import { Controller } from "@hotwired/stimulus"
import { marked } from "marked"

// Connects to data-controller="preview"
export default class extends Controller {
  static targets = [ "content" ]
  static values = {
    markdown: String
  }

  connect() {
    let previewContent = marked.parse(this.markdownValue)
    this.contentTarget.innerHTML = previewContent
  }
}
