import { Controller } from "@hotwired/stimulus"
import { marked } from "marked"

// Connects to data-controller="markdown"
export default class extends Controller {
  static targets = [
    "markdownTab",
    "previewTab",
    "markdownDiv",
    "previewDiv",
    "markdownContent",
    "previewContent"
  ]

  viewPreview() {
    let previewContent = marked.parse(this.markdownContentTarget.value)
    if (previewContent === "") {
      this.previewContentTarget.innerHTML = '<p class="is-size-3">您还没有输入任何内容</p>'
    } else {
      this.previewContentTarget.innerHTML = previewContent
    }

    this.previewTabTarget.classList.add("is-active")
    this.previewDivTarget.classList.remove("is-hidden")

    this.markdownTabTarget.classList.remove("is-active")
    this.markdownDivTarget.classList.add("is-hidden")
  }

  viewMarkdown() {
    this.previewTabTarget.classList.remove("is-active")
    this.previewDivTarget.classList.add("is-hidden")

    this.markdownTabTarget.classList.add("is-active")
    this.markdownDivTarget.classList.remove("is-hidden")
  }
}
