import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="count-down"
export default class extends Controller {
  static targets = [ "panel" ]
  static values = {
    endIn: Number
  }

  connect() {
    this.endIn = new Date(this.endInValue)
    this.countDown()
    this.spinFunc = this.countDown.bind(this)
    setInterval(this.spinFunc, 1000)
  }

  disconnect() {
    clearInterval(this.spinFunc)
  }

  countDown() {
    let now = Date.now()
    let panelText = ""

    if (this.endIn - now > 0) {
      let minDiff = Math.ceil((this.endIn - now) / 1000 / 60)
      panelText = `本次笔试还剩下${minDiff}分钟`
    } else {
      panelText = "本次笔试已结束"
      if (this.spinFunc) {
        clearInterval(this.spinFunc)
      }
    }

    if (this.panelTarget.innerText != panelText) {
      this.panelTarget.innerText = panelText
    }
  }
}
