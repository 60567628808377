import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="autosave"
export default class extends Controller {
  static targets = [ "input" ]
  static values = { url: String }

  connect() {
    this.cachedValue = this.inputValue
    this.autoSaveFunc = this.autosave.bind(this)

    setInterval(this.autoSaveFunc, 20000)
  }

  disconnect() {
    clearInterval(this.autoSaveFunc)
  }

  autosave() {
    if (this.cachedValue == this.inputValue) { return }

    fetch(this.urlValue, {
      method: "put",
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ answer: this.inputValue })
    })

    this.cachedValue = this.inputValue
  }

  get inputValue() {
    return this.inputTarget.value
  }
}
